//=================================================
// Typography
//=================================================

/*=============================
// Headings
=============================*/
h1, h2, h3, h4, h5, h6 {
    font-family: $ubuntu_b;
    color: $body_headline_color;
    margin-bottom: 1.01rem;
    
    &:last-child {
        margin-bottom: 0;
    }
}

h1 {
    font-size:10rem;
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;
    line-height: 1.15;
    @include lhCrop-b(1.15, 0.645);
    
    &:last-child, &.after {
        @include lhCrop-a(1.15, 0.75);
    }

    @media #{$media_xxl} {
        font-size: 8rem;
    }

    @media #{$media_sm} {
        font-size: 4.13rem;
        
        br {
            display: none;
        }
    }
    
    // Less than default
    &.small {
        font-size: 2.88rem;
        margin-bottom: 1.49rem;
        line-height: 1.21;
        @include lhCrop-b(1.21, 0.75);
        
        &:last-child, &.after {
            @include lhCrop-a(1.21, 0.75);
            margin-bottom: 0;
        }
    }
    
    &.h, &.H {
        margin-left: -0.069em;
    }
    
    &.c, &.C {
        margin-left: -0.038em;
    }
}

h2 {
    font-size: 2.959rem;
    line-height: 1.11;
    @include lhCrop-b(1.11, 0.65);
    
    &:last-child, &.after {
        @include lhCrop-a(1.11, 0.75);
    }
}

h3 {
    font-size: 2.256rem;
    line-height: 1.74;
    @include lhCrop-b(1.74, 0.75);
    
    &:last-child, &.after {
        @include lhCrop-a(1.74, 0.75);
    }
}

h4 {
    font-size: 1.72rem;
    line-height: 1.39;
    @include lhCrop-b(1.39, 0.68);
    
    &:last-child, &.after {
        @include lhCrop-a(1.39, 0.75);
    }
    
}

h5 {
    font-size: 1.312rem;
    line-height: 1.56;
    @include lhCrop-b(1.56, 0.75);
    
    &:last-child, &.after {
        @include lhCrop-a(1.56, 0.75);
    }
}

h6 {
    font-size: 1rem;
    line-height: 1.56;
    @include lhCrop-b(1.56, 0.70);
    margin-bottom: 1.02em;
    
    &:last-child, &.after {
        @include lhCrop-a(1.56, 0.75);
        margin-bottom: 0;
    }
}

/*=============================
// Customizing headings
=============================*/
// Line
.line {
    position: relative;
    padding-left: 0.63rem;
    padding-right: 0.63rem;
    z-index: 1;
        
    &:before {
        content: '';
        background: $body_main_element_color;
        position: absolute;
        left: 0;
        bottom: 0.56rem;
        width: 100%;
        height: 1.38rem;
        z-index: -1;
    }
}

h1 {
    .line {
        &:before {
            bottom: 19px;
        }
    }
    
    &.small {
        .line {
            &:before {
                bottom: 12px;
                height: 22px;
                
                @media #{$media_lg} {
                    bottom: 8px;
                }
                
                @media #{$media_sm} {
                    bottom: 7px;
                }
            }
        }
    }
}

// Dot
.dot {
    color: $body_main_element_color;
}

/*=============================
// Paragraph
=============================*/
p {
    line-height: 1.75;
    font-size: 1rem;
    @include lhCrop-b(1.75, 0.75);
    margin-bottom: 0;
    
    &:last-child {
        @include lhCrop-a(1.75, 0.75);
                    
        &[class*="bg-"] {
            &:after {
                display: none;
            }
        }
    }

    &.p-small {
        font-size: 0.81rem;
    }
    
    &.p-large {
        font-size: 1.31rem;
    }
}

// Span size
span {
    &.s-small {
        font-size: 0.81rem;
    }
    
    &.p-large {
        font-size: 1.31rem;
    }
}

// Bold
strong, b {
    font-family: $font_primary_bold;
}

.site-main {
    p {
        margin-bottom: $space_sm;

        &:last-child {
            margin-bottom: 0;
            
            img, svg {
                padding-bottom: 0;
            }
        }
        
        &.p-small {
            margin-bottom: 1.22rem;
            
            &:last-child {
                margin-bottom: 0;
            }
        }
        
        img, svg {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
            
        a {
            font-weight: bold;
        }
    }
    
    figure {
        &:last-child {
            margin-bottom: 0;
        }
                
        a {
            font-weight: bold;
        }
        
        figcaption {
            margin-bottom: 0;
            line-height: 1.75;
            @include lhCrop-b(1.75, 0.75);
            @include lhCrop-a(1.75, 0.75);
        }
    }
}

/*=============================
// Blockquote
=============================*/
blockquote, .blockquote {
    font-style: italic;
    font-weight: bold;
    border-left: 2px solid $body_main_element_color;
    padding-left: 1rem;
    line-height: 1.75;
    @include lhCrop-b(1.75, 0.75);
    margin-bottom: $space_sm;
    
    &:last-child {
        margin-bottom: 0;
        @include lhCrop-a(1.75, 0.75);
    }
    
    p {
        &:after, &:before {
            display: none;
        }
        
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

/*=============================
// Lists
=============================*/
ul {
    list-style: none;
    margin-left: 1.3rem;
    padding: 0;

    li {
        font-family: $font_primary;
        line-height: 1.23;
        margin-bottom: 1.39rem;
        @include lhCrop-b(1.23, 0.75);
        

        &:last-child {
            @include lhCrop-a(1.23, 0.75); 
            margin-bottom: 0;
        }
        
        &:before {
            content: "\2022";
            color: $body_main_element_color;
            font-weight: 700;
            width: 1rem;
            margin-left: -1.3rem;
        }
    }
    
    ol, ul {
        margin-top: 1.39rem;
    }
}

ol {
    margin-left: 1.3rem;
    padding: 0;

    li {
        line-height: 1.23;
        margin-bottom: 1.39rem;
        @include lhCrop-b(1.23, 0.75);
        margin-bottom: 1.39rem;
        
        &:last-child {
            @include lhCrop-a(1.23, 0.75); 
            margin-bottom: 0;
        }
        
        ul {
            > li {
                &:before {
                    content: "\2022";
                }
            }
        }
        
        ol {
            > li {
                &:before {
                    content: "";
                }
            }
        }
        
        strong, p {
            line-height: 1.23;
        }
        
        p {
            @include lhCrop-b(1.23, 0.75);
            margin-top: 1.39rem;
            margin-bottom: 1.39rem;
            
            &:first-child {
                margin-top: 0;
            }
            
            &:last-child {
                @include lhCrop-a(1.23, 0.75);
                margin-bottom: 0;
            }
        }
    }
    
    ol, ul {
        margin-top: 1.39rem;
    }
}

// List unstyled
.list-unstyled {
    margin-left: 0;
        
    li {
        @include lhCrop-a(1.23, 0.75); 
        
        &:before {
            content: "";
        }
    }
}

// List group item unstyled
.list-group-item, [class*="icon-list-item"] {
    &:before {
        display: none;
    }
}

address, dl, ol, ul, pre {
    margin-bottom: $space_sm;
                
    &:last-child {
        margin-bottom: 0;
    }
}

address, dl {
    line-height: 1.75;
    @include lhCrop-b(1.75, 0.75);
    
    &:last-child {
        @include lhCrop-a(1.75, 0.75);
    }
}

pre {
    line-height: 1.75;
}

/*=============================
// Emphasis
=============================*/
mark, .mark {
    background-color: $body_mark_background;
}

/*=============================
// Date
=============================*/
p.date {
    font-family: $font_secondary;
    font-size: 0.81rem;
    color: $body_date_color;
    @include lhCrop-b(1.75, -0.25);
    margin-bottom: 0.82rem;
    
    &:last-child {
        @include lhCrop-a(1.75, -0.25);
    }
    
    .large {
        font-family: $font_secondary_bold;
        font-size: 1.56rem;
        margin-right: 0.38rem;
    }
}

/*=============================
// Text format
=============================*/
.text-secondary {
    color: $body_text_secondary_color !important;
}

.text-primary, 
.text-secondary, 
.text-success,
.text-danger,
.text-warning, 
.text-info, 
.text-light, 
.text-dark,
.text-body,
.text-muted,
.text-white,
.text-black-50,
.text-white-50 {
    &.regular {
        font-family: $font_secondary; 
    }
    
    &.bold {
        font-family: $font_secondary_bold;
    }
}

/*=============================
// Hr
=============================*/
hr {
    margin-bottom: $space_sm;
                
    &:last-child {
        margin-bottom: 0;
    }
}

/*=============================
// Pullquote
=============================*/
[class*="pullquote"] {
    border-top: 2px solid;
    border-bottom: 2px solid;
    
    .blockquote, blockquote {
        font-style: normal;
        border: 0;
        padding-left: 0;
        
        cite {
            font-style: normal;
        }
    }
}

/*=============================
// Caption
=============================*/
caption {
    padding-top: 0;
    padding-bottom: 0;
}

caption, figcaption {
    font-size: 0.88rem;
}
